@font-face {
  font-family: 'NoeDisplay';
  src: url('assets/fonts/Noe-Display-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'RivieraNights';
  src: url('assets/fonts/Riviera-Nights-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@import 'variables';

@import '~bootstrap/scss/bootstrap.scss';

html {
  font-size: 15px;
  background: url(assets/images/background-light.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

body, html {
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
}

body {
  display: flex;
}

.border-primary {
  border-color: rgba(map-get($theme-colors, 'primary'), 0.5) !important;
}

.btn:focus {
  box-shadow: none;
}

.btn-link {
  font-family: 'NoeDisplay';
  padding: 0;
  color: black;

  > img {
    border: 2px solid transparent;
  }

  small {
    font-family: 'RivieraNights';
  }

  &.active {
    color: map-get($theme-colors, 'primary');

    small {
      font-weight: bold;
    }
  }

  &.active > img,
  &:hover > img {
    border-color: map-get($theme-colors, 'primary');
  }
}

.btn-link-material {
  border: 2px solid transparent;

  &.active,
  &:hover {
    border-color: map-get($theme-colors, 'primary');
  }
}

.btn-link-header {
  padding: 0.375rem 0.75rem;
  border-top: none;
  border-left: none;
  border-right: none;
}

.collapsing {
  -webkit-transition: none;
  transition: none;
  display: none;
}

.d-flex {
  min-height: 0;
}

.row {
  min-height: 0;
}

.hide-images img {
  display: none;
}

::-webkit-scrollbar-track
{
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: transparent;
}

::-webkit-scrollbar
{
  width: 6px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb
{
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: map-get($theme-colors, 'light');
}
