@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

$font-family-base: "RivieraNights", sans-serif;

$headings-font-family: "NoeDisplay", sans-serif;

$theme-colors: (
  "primary": #e05538,
);

$enable-rounded: false;
$enable-responsive-font-sizes: true;

$body-bg: transparent;

$link-hover-color: map-get($theme-colors, "primary");
$link-hover-decoration: none;

$text-muted: map-get($theme-colors, "secondary");

$headings-margin-bottom: 0;

$viewer-min-height: 30vh;
